'use client';

import { useGlobalStore } from '@/presenters/store/global';

export function useAnalyticsTags() {
  const { device, isLoggedIn, isOnMobileApp, region } = useGlobalStore(
    (store) => ({
      region: store.user.session.region,
      isLoggedIn: store.user.controls?.isLoggedIn,
      isOnMobileApp: store.server.isOnMobileApp,
      device: store.server.device,
    })
  );

  const analyticsTags: string[] = [];

  if (!region) {
    return analyticsTags;
  }

  analyticsTags.push(`#${region}`);
  analyticsTags.push(isLoggedIn ? '#loginOn' : '#loginOff');
  analyticsTags.push(`#${isOnMobileApp ? 'app' : device}`);

  return analyticsTags;
}
