'use client';

import { useEffect, type PropsWithChildren, useRef } from 'react';

import { Toaster } from '@backyard-ui/core';
import { BackyardProvider, screens } from '@backyard-ui/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import mediaQuery from 'css-mediaquery';

import { setAppRegion, onPageLoad } from '@/infra/tracking/tracker';
import { useGetUserData } from '@/presenters/hooks/useGetUserData';
import {
  GlobalProvider,
  createGlobalStore,
  type GlobalState,
} from '@/presenters/store/global';
import { buildUser } from '@/presenters/store/utils/build-user';
import '@/infra/monitoring/datadog-monitor';

interface ProvidersProps extends PropsWithChildren, Omit<GlobalState, 'user'> {
  shouldSendLocationToApp: boolean;
  region: GlobalState['user']['session']['region'];
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function Providers(props: ProvidersProps) {
  const { server, region, shouldSendLocationToApp, children } = props;

  const { userSession, userResources, loyaltyProfile } = useGetUserData();

  const initialUser = {
    session: { region },
  };

  const store = useRef(
    createGlobalStore({ server, user: initialUser })
  ).current;

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    store.setState({
      user: buildUser({
        userResources,
        loyaltyProfile,
        session: userSession || initialUser.session,
      }),
    });
  }, [
    loyaltyProfile,
    server.isOnMobileApp,
    store,
    userSession,
    userResources,
    initialUser.session,
  ]);

  useEffect(() => {
    if (
      !server.isOnMobileApp ||
      !userSession?.regionName ||
      !shouldSendLocationToApp
    ) {
      return;
    }

    setAppRegion({
      regionName: userSession.regionName,
      region: userSession.region,
    });
  }, [userSession, shouldSendLocationToApp, server.isOnMobileApp]);

  const serverMatchMedia = (query: string) => ({
    matches: mediaQuery.match(query, {
      //@ts-ignore
      type: 'screen',
      width:
        server.device === 'mobile'
          ? mediaQuery.parse(`screen and (min-width: ${screens.sm})`)[0]
              .expressions[0].value
          : mediaQuery.parse(`screen and (min-width: ${screens.xl})`)[0]
              .expressions[0].value,
    }),
  });

  return (
    <GlobalProvider value={store}>
      <QueryClientProvider client={queryClient}>
        <BackyardProvider
          components={{
            UseMediaQuery: {
              defaultProps: {
                serverMatchMedia,
              },
            },
          }}
        >
          <Toaster />
          {children}
        </BackyardProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </GlobalProvider>
  );
}
