import search from 'algoliasearch/lite';
import { type InsightsClient } from 'instantsearch.js/es/types/insights';
import aa from 'search-insights';

import { cookies } from '@/utils/cookies';

const USER_ID = cookies.client.get('user_id').value;

const APP_ID = process.env.NEXT_PUBLIC_SDK_ALGOLIA_APP_ID;
const API_KEY = process.env.NEXT_PUBLIC_SDK_ALGOLIA_API_KEY;

export const searchClient = search(APP_ID, API_KEY);

if (USER_ID) {
  aa('init', {
    appId: APP_ID,
    apiKey: API_KEY,
    userToken: USER_ID,
  });
} else {
  aa('init', {
    appId: APP_ID,
    apiKey: API_KEY,
    useCookie: true,
  });
}

export const insightsConfig = {
  insightsClient: aa as InsightsClient,
};

export const indexName = process.env.NEXT_PUBLIC_SDK_ALGOLIA_INDEX_NAME;
